$background-color: #FFA9A2;

.robin {
  height: 60vh;
  max-height: 100%
}

.hero {
  &.is-success {
    background-color: $background-color;
  }
}

.slack-button {
  margin-top: 1em;
}
.powered-by {
  margin-top: 2em;
}
